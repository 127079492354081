import React from 'react';
import MainLayout from '../../layouts/main';
import { Typography } from '@material-ui/core';
import { SEO } from '../../components/SEO';
import { SignupButtonWithChoice } from '../../components/SignupButton';
import PageHead from '../../components/PageHead';
import {
  FeatureGrid,
  RevisionsWorkflow,
  RevisionsIgnore,
  RevisionsAutomatic
} from '../../components/FeatureGrid';
import Section, { Label } from '../../components/Section';
import { VIDEOS, VimeoVideo } from '../../components/VimeoVideo';

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/product/revisions/"
          title="Revision Tracking for Affiliate Publishers"
          description="Track whether your content edits improved clicks, earnings, and earning efficiency."
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '630px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Track the impact of every edit on your commerce content's affiliate
            revenue
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ maxWidth: '510px', margin: '24px auto 12px' }}
          >
            Affilimate automatically splits your affiliate revenue by click date
            and content revision, so you can identify which changes improved
            conversion and remember what you changed.
          </Typography>
          <div style={{ maxWidth: '800px', margin: '24px auto 36px' }}>
            <VimeoVideo videoId={VIDEOS.REVISION} />
          </div>
          <div style={{ textAlign: 'center', margin: '48px auto 0' }}>
            <SignupButtonWithChoice text="Track the impact of your content changes" />
          </div>
        </PageHead>
        <div style={{ maxWidth: '900px', margin: '96px auto 64px' }}>
          <Typography
            variant="h5"
            gutterBottom
            component="h2"
            style={{
              textAlign: 'center',
              fontWeight: 700
            }}
          >
            Break down your content analytics for every revision
          </Typography>
          <Typography
            variant="body1"
            component="p"
            paragraph
            style={{
              maxWidth: '510px',
              margin: '0 auto',
              textAlign: 'center'
            }}
          >
            Use the revision selector to see a snapshot of your content
            analytics for any version of the page that's been live since you
            started tracking.
          </Typography>
        </div>

        <Section
          image="/images/features/content-analytics-trend-details.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Revision markers</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Correlate content edits directly with both revenue and on-page
                metrics
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Visualize exactly when a content edit was made and its impact on
                your clicks, commissions, and other key metrics.
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/new-heatmaps.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Heatmaps</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Remember exactly what you changed with your latest edit
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Jump back to view any past revision with heatmaps so you know
                what you changed you changed during your latest edit or content
                refresh.
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/link-performance-revisions.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Link performance</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Compare how your affiliate links perform between revisions
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                See how your content edits impact the performance of links on
                any page. Watch for changes in click-through rate, earnings per
                click, and other core metrics.
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/new-revisions.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Revisions</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Monitor the overall impact on your content's profitability
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Split your affiliate revenue, clicks, RPM, and earnings for
                different versions of your page or post. Fits perfectly into
                your existing workflow.
              </Typography>
            </>
          }
        />
        <div style={{ maxWidth: '900px', margin: '96px auto 64px' }}>
          <Typography
            variant="h5"
            gutterBottom
            component="h2"
            style={{
              textAlign: 'center',
              fontWeight: 700
            }}
          >
            Understand the impact of every content edit you've made
          </Typography>
          <Typography
            variant="body1"
            component="p"
            paragraph
            style={{
              maxWidth: '520px',
              margin: '0 auto',
              textAlign: 'center'
            }}
          >
            Then use that knowledge to scale your most profitable strategies
            across your website or portfolio
          </Typography>
        </div>
        <FeatureGrid>
          <RevisionsWorkflow />
          <RevisionsIgnore />
          <RevisionsAutomatic />
        </FeatureGrid>
      </div>
    </MainLayout>
  );
};
