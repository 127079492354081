import React from 'react';

interface VimeoVideoProps {
  videoId: string;
}

export const VIDEOS = {
  LINK_GENERATOR: '876646491?h=86863be752',
  HEATMAPS: '876652713',
  REVISION: '876656999?h=5f01ff3987'
};

export const VimeoVideo: React.FC<VimeoVideoProps> = ({ videoId }) => {
  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: '56.25%',
        height: 0,
        overflow: 'hidden'
      }}
    >
      <iframe
        src={`https://player.vimeo.com/video/${videoId}`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%'
        }}
        title="Vimeo Video"
      ></iframe>
    </div>
  );
};
